import { _LoadDataChild, _mapChilds } from "../../data/services/Kids";
import { _APP_DATA } from "../../data/AppData";
import { LOADING } from "../components/Loading";
import { BClsRoom } from "../bases/BClsRoom";


export class ClassRoom extends BClsRoom {

    constructor() {
        super();

        this.IS_EXTEMPORAL = false;
    }

    protected LoadChild(): void {
        this.LoadFromMapData();

        if (_mapChilds.size > 0)
            LOADING.Dismiss();

        _LoadDataChild((_) => {
            LOADING.Dismiss();

            this.LoadFromMapData();
        });
    }

    private LoadFromMapData() {
        const resList = Array.from(_mapChilds.values());
        if (resList.length == 0) return;

        this.childList = resList.filter(o => {
            let idGroup = o.GrupoActivo < 0 ? (o.GrupoActivo * -1) : o.GrupoActivo;
            return o.EnKinder && idGroup == _APP_DATA.userData.IdGrupo;
        });

        this.SetListBubbleChild();
        this.DragAllItems();
    }

}