import { Selection, select } from "d3";
import "../../../styles/components/Menu-options.scss";
import ic_classroom from '/icons/ic_classroom.svg?raw'
import ic_enter_exit from '/icons/ic_enter_departure.svg?raw'
import ic_home from '/icons/ic_home.svg?raw'
import ic_extemporaneos from '/icons/ic_extemporaneos.svg?raw'
import ic_chat from '/icons/ic_chat.svg?raw'
import ic_agenda from '/icons/ic_agenda.svg?raw'
import ic_exit_session from '/icons/ic_logout.svg?raw'
import ic_qr from '/icons/ic_qr.svg?raw'
import ic_report from '/icons/ic_report.svg?raw'
import ic_kidiLogoScary from '/icons/ic_kidi_logo_scary.svg?raw'
import _L from '../../utils/Labels';
import { LOADING } from "./Loading";
import { _HashAssign, _HashReplace } from "../../routes/UIManager";
import { _Logout, _SaveUserData, _ScanQR } from "../../utils/Device";
import { _GetAppBuildTag } from "../../utils/General";
import { ConfirmDialog } from "./ConfirmDialog";
import { ShowToast } from "./Toast";
import { EEventId } from "../../data/models/Entities";
import { THashPath } from "../../routes/Routes";
import { _SetInfoUser, _SignOut } from "../../data/services/UserInfo";
import { _RestartAndCreateDB } from "../../data/utils/General";
import { _SetGroupInUserInfo } from "../../data/ManageUserInfo";
import { _LoadKinder, _kinder } from "../../data/services/Kinder";
import { _mapConfiguration } from "../../data/services/Configuration";
import { _APP_DATA } from "../../data/AppData";

interface IMenuOptions {
    id: number;
    title: string;
    icon: string;
    hash?: THashPath;
}

export class MenuOptions {

    private mainContainer: Selection<HTMLDivElement, any, any, any>;
    private options: Map<THashPath, IMenuOptions> = new Map([
        ["home", { id: 1, icon: ic_enter_exit, title: _L("modulo.E/S"), hash: "home" }],
        ["classroom", { id: 2, icon: ic_classroom, title: _L("modulo.salon"), hash: "classroom" }],
        ["extemporaneo", { id: 3, icon: ic_extemporaneos, title: _L("modulo.extemp"), hash: "extemporaneo" }],
        // [EHashUI.CHAT, { id: 4, icon: ic_chat, title: _L("modulo.chat"), hash: EHashUI.CHAT }],
        ["agenda", { id: 5, icon: ic_agenda, title: _L("modulo.agenda"), hash: "agenda" }],
        ["report", { id: 6, icon: ic_report, title: _L("modulo.report"), hash: "report" }],
        // { id: 7, icon: ic_qr, title: 'QR', hash: EHashUI.QR },
        ["groups", { id: 8, icon: ic_home, title: _L("modulo.home"), hash: "groups" }],
        ["login", { id: 9, icon: ic_exit_session, title: _L("sesion.logout"), hash: "login" }],
    ]);

    private divOptions: Selection<HTMLDivElement, any, any, any>;

    constructor() {
        this.mainContainer = select("body").append("div").classed("ui-menu-options", true);
        this.divOptions = this.mainContainer.append("div").classed("options-container", true);

        this.SetOptions();
        this.CreateLogoOptions();

        this.mainContainer.on("click", () => {
            this.OnDestroy();
        });
        this.mainContainer.append("div")
            .attr("class", "version_tag")
            .text(_GetAppBuildTag());

        let isShowQR = _kinder && _kinder.BolQr;
        if (isShowQR) //Agregar QR
            this.options.set("qrview", { id: 7, icon: ic_qr, title: _L("modulo.qr"), hash: "qrview" });

        let itemChat = Array.from(_mapConfiguration.values()).filter(o => o.Tipo == EEventId.CHAT);
        if (itemChat.length > 0)
            this.options.set("chat", { id: 4, icon: ic_chat, title: _L("modulo.chat"), hash: "chat" });

        this.SetOptions();

        _LoadKinder(() => {
            let isShowQR = _kinder && _kinder.BolQr;
            if (isShowQR) //Agregar QR
                this.options.set("qrview", { id: 7, icon: ic_qr, title: _L("modulo.qr"), hash: "qrview" });
            else
                this.options.delete("qrview");

            this.SetOptions();
        });
    }


    private SetOptions() {
        const resList = Array.from(this.options.values());
        resList.sort((a, b) => a.id - b.id);
        const btns = this.divOptions.selectAll<HTMLDivElement, any>(".btn-options").data(resList);

        btns.exit().remove();
        btns.enter().append("div")
            .classed("btn-options", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.append("div")
                    .classed("img-btn-option", true)
                    .attr("alt", "reportes");

                elemnt.append("label")
            })
            .merge(btns)
            .on('click', (_, datum) => this.OnSelectOption(datum))
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);
                elemnt.select(".img-btn-option").html(datum.icon);
                elemnt.select("label").html(datum.title);
            });
    }

    private async OnSelectOption(datum: IMenuOptions) {
        if (datum.hash == "login") {//logout
            this.OnLogout();
        } else if (datum.hash == "qrview") {
            _ScanQR();
        } else {
            if (datum.hash == "groups") {
                await _RestartAndCreateDB();

                _SetGroupInUserInfo({
                    IdGrupo: 0,
                    IdEscuela: (_APP_DATA.userData?.IdKinder || 0),
                    HoraInicio: "",
                    HoraFin: "",
                    IdNivel: 0,
                    NombreGrupo: "",
                    NombreNivel: "",
                    EsPrincipal: false,
                    NMessages: 0
                });
                _HashReplace(datum.hash);
            } else {
                _HashAssign(datum.hash);
            }
        }
    }

    private CreateLogoOptions() {
        this.mainContainer.append("div").classed("main-options", true)
            .html(ic_kidiLogoScary)
            .select("svg")
            .attr("class", "img-logo-options")
    }

    private OnLogout() {
        new ConfirmDialog()
            .SetTitle(_L("sesion.title_confirm"))
            .SetDescription(_L("sesion.description"))
            .SetOnConfirm(() => {
                LOADING.Show();

                _SignOut(async (resStatus) => {
                    if (resStatus === 1) {
                        await _RestartAndCreateDB();
                        _Logout();
                    } else {
                        ShowToast(_L("sesion.logout"), _L("sesion.logout_fail"), 'error');
                    }

                    LOADING.Dismiss();
                });
            });
    }

    OnDestroy() {
        if (!this.mainContainer) return;
        this.mainContainer.remove();
    }
}