import "../../../styles/pages/Extemp.scss";
import { _APP_DATA } from "../../data/AppData";
import { _LoadExtempDataChild } from "../../data/services/Kids";
import { _Label as _L, _HttpMsg } from "../../utils/Labels";
import { LOADING } from "../components/Loading";
import { ShowToast } from "../components/Toast";
import { BClsRoom } from "../bases/BClsRoom";

export class Extemporaneos extends BClsRoom {

    constructor() {
        super();

        this.IS_EXTEMPORAL = true;
    }

    protected LoadChild(): void {
        _LoadExtempDataChild((status, resList) => {
            LOADING.Dismiss();
            if (status < 0) {
                ShowToast(_L("extemporal.child_extemp"), _HttpMsg("alumno/ObtenerExtemporaneosDisponiblesHoy", status), 'error');
            }

            if (resList.length == 0) return;

            this.childList = resList;

            this.SetListBubbleChild();
            this.DragAllItems();
        })
    }

    protected CreateHeader(addOptions: boolean): void {
        super.CreateHeader(addOptions);

        let title = _APP_DATA.userData.NombreGrupo;
        const teacherName = _APP_DATA.userData.NombreMaestra + " " + _APP_DATA.userData.ApPaternoMaestra + " " + _APP_DATA.userData.ApMaternoMaestra;
        if (title && teacherName.trim()) title += " - "
        title += teacherName.trim() != "" ? teacherName : "";
        title += ` (${_L("extemporal.title")})`;

        this.headerContainer.select(".title").html(`<span class="status-extemp"></span>` + title);
    }
}