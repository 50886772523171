import '../../../styles/pages/Report-child.scss'
import ic_com_medicina from '/icons/ic_comentario-medicina.svg?raw'
import ic_com_normal from '/icons/ic_comentario-normal.svg?raw'
import ic_com_importante from '/icons/ic_comentario-importante.svg?raw'
import ic_exit from '/icons/ic_exit.svg?raw'
import ic_enter from '/icons/ic_enter.svg?raw'
import ic_departure from '/icons/ic_departure.svg?raw'
import _L from '../../utils/Labels';
import { Selection, select, timeDay } from "d3";
import { Base } from "../bases/Base";
import { ECalificacionTipoEval, ECategoryHygiene, EEstadoLogro, EEventId, ETypeBath, FOOD_TYPE_VALUES, ICalendarEvent, IChild, IEvent, IEventChild } from '../../data/models/Entities';
import { _CreateElementFromHTML, _EVENTS_ES_INFO } from "../utils/General";
import { LOADING } from '../components/Loading';
import { _GetEventPhotoURL, _LoadCalendarEvent, _LoadCatalogFoods, _LoadEventByChild, _mapHumorValues } from '../../data/services/Event';
import { FormatDateToHour } from '../../data/utils/General';
import { _GetKinderEventsArray, _GetKinderEventsMap } from '../../data/services/Kinder';
import { _AutoLink } from "../utils/General";
import { ImageViewer } from '../components/ImageViewer';
import { _HistoryBack } from '../../routes/UIManager';
import { _LoadGroupsByIds, _gruposInfoMap } from '../../data/services/Group'
import { _APP_DATA } from '../../data/AppData'

type IReportEvent = {
    [k in EEventId]: {
        id: string,
        idEvent: k
        idGroup: number;
        iconSVGRaw: string
        title: string
        date: Date | null
        dateFmt: string
    }
    & (
        k extends EEventId.PHOTOS
        ? {
            items: {
                idFoto: number
                description: string
                date: Date | null
            }[]
        }
        : {
            description: string
        }
    )
}[EEventId];
type IReportEventPhoto = IReportEvent & { idEvent: EEventId.PHOTOS };

const CommentIcons = {
    0: ic_com_normal,
    1: ic_com_importante,
    2: ic_com_medicina,
}

export class ReportChild extends Base {

    private headerContainer: Selection<HTMLDivElement, any, any, any>;
    private bodyContainer: Selection<HTMLDivElement, any, any, any>;

    private childSelected: IChild;
    private evCalendar: ICalendarEvent;
    private eventsInfo: Partial<{ [k in EEventId]: Partial<IEvent> }>;

    constructor() {
        super('report-child', true);

        this.childSelected = history.state;

        LOADING.Show();

        this.headerContainer = this.mainContainer.append("div").classed("header", true);
        this.bodyContainer = this.mainContainer.append("div").classed("body", true);

        this.CreateHeader();
        // this.DrawListEvents();

        this.LoadData();
    }

    private async LoadData() {
        let dtStart = timeDay.floor(new Date());
        let dtEnd = new Date(new Date(dtStart).setDate(dtStart.getDate() + 1));

        this.eventsInfo = await _GetKinderEventsMap();
        this.eventsInfo = {
            ...this.eventsInfo,
            ..._EVENTS_ES_INFO,
        }
        _LoadCalendarEvent(dtStart, dtEnd, null, (resEventCalendar) => {
            this.evCalendar = resEventCalendar.filter((d) => !d.DeKinder && timeDay.floor(d.Inicio).getTime() === timeDay.floor(new Date()).getTime())[0];
            console.log(this.evCalendar, "////")

            _LoadEventByChild(this.childSelected.IdChild, dtStart, dtEnd, (resEvChilds, success) => {
                _LoadCatalogFoods((_) => {
                    this.SetEventsList(success ? resEvChilds : null);

                    const idsGrupos = [...new Set(
                        resEvChilds.reduce<number[]>((res, evChild) => {
                            if (evChild.IdGrupo > 0 && !_gruposInfoMap.has(evChild.IdGrupo)) {
                                res.push(evChild.IdGrupo);
                            }
                            return res;
                        }, [])
                    )]
                    if (idsGrupos.length) {
                        _LoadGroupsByIds(idsGrupos)
                            .then(() => {
                                this.SetEventsList(success ? resEvChilds : null);
                            });
                    }
                    LOADING.Dismiss();
                });
            });
        });
    }

    private CreateHeader() {
        this.headerContainer.append("div").classed("txt-titles", true)
            .call((txtTitles) => {
                const alumno = this.childSelected;
                const fulName = alumno.Nombre + " " + alumno.ApPaterno + " " + alumno.ApMaterno;
                txtTitles.append("b").text(_L("reportchild.title") + ": ");
                txtTitles.append("span").classed("txt-secundary", true).text(fulName);
            });

        this.headerContainer.append(() => _CreateElementFromHTML(ic_exit))
            .attr("class", "img-exit")
            .attr("alt", "close-icon")
            .on("click", () => {
                _HistoryBack();
            });
    }

    private SetEventsList(listEventChild: Array<IEventChild> | null) {
        const listResultFinal = this.ResolveListEvent(listEventChild || []);
        this.DrawListEvents(listResultFinal, listEventChild != null);
    }

    private ResolveListEvent(listEventChild: Array<IEventChild>) {
        const listResult: IReportEvent[] = [];
        let grupo: number;
        listEventChild
            .sort((a, b) => a.Inicio.getTime() - b.Inicio.getTime())
            .forEach((item, i) => {
                const evtInfo: Partial<IEvent> = this.eventsInfo[item.TipoEvento];
                if (!evtInfo || item.TipoEvento == EEventId.ENTRADA_SALIDA_GRUPO) return;
                if (item.TipoEvento == EEventId.ENTRADA_SALIDA_ESCUELA) {
                    item.Inicio?.setSeconds(0);
                    item.Inicio?.setMilliseconds(0);
                }

                const idGrupoItem = item.IdGrupo //|| item.Identificador;
                if (idGrupoItem && grupo != idGrupoItem) {
                    const nombreGrupo = _gruposInfoMap.get(idGrupoItem)?.Nombre
                    listResult.push(<IReportEvent>{
                        id: "group-" + idGrupoItem,
                        title: _L("reportchild.group") + ":",
                        description: nombreGrupo || _L("general.no_available"),
                        idGroup: idGrupoItem,
                        date: item.Inicio,
                    })
                }
                if (grupo != idGrupoItem) {
                    grupo = idGrupoItem;
                }

                let description = "";
                let title: string | null = null;
                let iconSVGRaw = evtInfo.iconSVGRaw;
                let dtStart = item.Inicio;
                let dateFmt = FormatDateToHour(item.Inicio);
                let fotosItems: IReportEventPhoto["items"] = [];

                if (item.TipoEvento == EEventId.SNOOZE) {
                    if (item.Fin) {
                        dateFmt += " - " + FormatDateToHour(item.Fin);
                    } else {
                        description = _L("snooze.inprogress");
                    }
                } else if (item.TipoEvento === EEventId.FOOD) {
                    const strFood = ({
                        [FOOD_TYPE_VALUES.COMIDA_CASA]: _L("food.food_house"),
                        [FOOD_TYPE_VALUES.PASTEL]: _L("food.cake"),
                    })[item.TipoCategoria] || item.Evento;

                    description = strFood;
                    if (item.Porcion)
                        description += `\n${_L("food.porcion")}: ${item.Porcion}`;

                } else if (item.TipoEvento === EEventId.HYGIENE) {

                    switch (item.Categoria) {
                        case ECategoryHygiene.PIPI: title = " " + _L("hygiene.pipi"); break;
                        case ECategoryHygiene.POPO: title = " " + _L("hygiene.popo"); break;
                        case ECategoryHygiene.DIENTES: title = " " + _L("hygiene.dients"); break;
                    }

                    switch (item.TipoCategoria) {
                        case ETypeBath.ACCIDENTE: description = ` (${_L("hygiene.accident")}) `; break;
                        case ETypeBath.INTENTO: description = ` (${_L("hygiene.intent")}) `; break;
                    }

                    description += item.Comentarios;

                } else if (item.TipoEvento === EEventId.ACTIVITIES) {
                    description = item.Evento + "\n" + item.Comentarios;

                } else if (item.TipoEvento === EEventId.MILLESTONE) {
                    switch (item.Categoria) {
                        case EEstadoLogro.INICIADO: title = _L("milestone.nuevameta"); break;
                        case EEstadoLogro.AVANZADO: title = _L("milestone.avance_s"); break;
                        case EEstadoLogro.TERMINADO: title = _L("milestone.alcanzado"); break;
                    }

                    description = item.Evento;

                } else if (item.TipoEvento === EEventId.EVALUATION) {
                    description = item.Evento + "<br>"
                        + (item.TipoCategoria == ECalificacionTipoEval.Colores
                            ? (!item.Porcion ? `<i>${_L("general.no_apply")}</i>` : `<div style="background-color: ${item.Porcion};" class="eval_color"></div>`) + "<br>"
                            : (!item.Porcion ? `<i>${_L("general.no_apply")}</i>` : item.Porcion) + (item.Comentarios ? ": " : ""))
                        + `<i>${item.Comentarios}</i>`;
                } else if (item.TipoEvento === EEventId.PHOTOS) {
                    const photosGroup = listResult.find(d => (d.idEvent == EEventId.PHOTOS && d.dateFmt == dateFmt)) as IReportEventPhoto;
                    if (photosGroup) {
                        photosGroup.items.push({
                            date: dtStart,
                            description: "",
                            idFoto: item.Identificador,
                        });
                        return;
                    } else {
                        fotosItems.push({
                            date: dtStart,
                            description: "",
                            idFoto: item.Identificador,
                        })
                    }
                } else if (item.TipoEvento === EEventId.COMMENTS) {
                    description = item.Comentarios;
                    if (CommentIcons[item.Categoria]) {
                        iconSVGRaw = CommentIcons[item.Categoria];
                    }
                } else if (item.TipoEvento === EEventId.HUMOR) {
                    let id = item.Identificador > 0 ? item.Identificador : item.Categoria;
                    let eHumor = _mapHumorValues.get(id);

                    if (!eHumor) return;//continue;

                    title = eHumor.label;
                    description = item.Comentarios;
                    iconSVGRaw = eHumor.iconSVGRaw;

                } else if (item.TipoEvento === EEventId.ENTRADA_SALIDA_ESCUELA) {
                    if (item.Inicio) {
                        title = _L("E/S.entrada");
                        listResult.push({
                            id: i.toString(),
                            idEvent: EEventId._ENTRADA_ESCUELA,
                            idGroup: grupo,
                            title: title || evtInfo.name,
                            iconSVGRaw: ic_enter,
                            description: description,
                            date: item.Inicio,
                            dateFmt: FormatDateToHour(item.Inicio),
                        })
                    }

                    if (item.Inicio && item.Fin) {
                        title = _L("E/S.salida");
                        listResult.push({
                            id: i.toString(),
                            idEvent: EEventId._SALIDA_ESCUELA,
                            idGroup: grupo,
                            title: title || evtInfo.name,
                            iconSVGRaw: ic_departure,
                            description: description,
                            date: item.Fin,
                            dateFmt: FormatDateToHour(item.Fin),
                        })
                    }

                    return;
                }

                listResult.push({
                    id: i.toString(),
                    idEvent: item.TipoEvento,
                    idGroup: grupo,
                    title: title || evtInfo.name,
                    iconSVGRaw: iconSVGRaw,
                    description: description,
                    date: dtStart,
                    dateFmt: dateFmt,
                    items: fotosItems,
                })
            });

        return listResult
            .sort((a, b) => {
                const incluyeEventoESEscuela = a.idEvent == EEventId._ENTRADA_ESCUELA;
                const inicio = a.date?.getTime() ? (a.date.getTime() - (incluyeEventoESEscuela ? 1 : 0)) : 0;
                if (inicio && b.date)
                    return inicio - b.date.getTime()
                return 0;
            })
            .reduce((res, currentItem, i) => {
                if (currentItem.idEvent == EEventId.PHOTOS) {
                    let lastIndex = i - 1;
                    let lastItem = listResult[lastIndex] as IReportEventPhoto;
                    let hasLastItem = lastItem && lastItem.idEvent == EEventId.PHOTOS;
                    if (hasLastItem) {
                        while (lastItem) {
                            const isLastLastItemValid = listResult[lastIndex - 1] && listResult[lastIndex - 1].idEvent == EEventId.PHOTOS;
                            if (isLastLastItemValid) {
                                lastIndex--;
                                lastItem = listResult[lastIndex] as IReportEventPhoto;
                            }
                            else {
                                // Primer grupo de fotos valido
                                lastItem.items.push(...currentItem.items);
                                if (!listResult[i + 1] || listResult[i + 1].idEvent != EEventId.PHOTOS) {
                                    lastItem.dateFmt += " - " + FormatDateToHour(currentItem.date);
                                }
                                lastItem = null;
                            }
                        }
                        return res;
                    }
                }
                res.push(currentItem);
                return res;
            }, <IReportEvent[]>[]);
    }


    private DrawListEvents(listResult: IReportEvent[], successLoad: boolean) {
        this.bodyContainer.attr("text-tag", !successLoad ? _L("reportchild.load_error") : !listResult.length ? _L("reportchild.no_events") : null);

        this.bodyContainer.selectAll<HTMLDivElement, IReportEvent>(".item-ev-report")
            .data(listResult, (d) => d.id)
            .join("div")
            .classed("item-ev-report", true)
            .each((datum, i, divs) => {
                const contElement = divs[i];
                const contSelection = select(contElement);

                if (contElement.childElementCount == 0) {
                    contSelection.append("div").classed("icon-event", true);
                    const titleSubtitle = contSelection.append("div").classed("title-subtitle", true);
                    titleSubtitle.append("h4").attr("class", "name");
                    titleSubtitle.append("h4").attr("class", "description");
                    titleSubtitle.append("label").attr("class", "date");
                }

                if (datum.idEvent !== undefined) {
                    const eventClassBase = "event-icon-";
                    const lastClass = [...contElement.classList].find(c => c.startsWith(eventClassBase))
                    if (lastClass) {
                        contSelection.classed(lastClass, false);
                    }
                    contSelection.classed(eventClassBase + datum.idEvent, true);
                }
                else
                    contSelection.classed("grupo grupo-" + datum.idGroup, true);

                // const evto = EVENTS_INFO_MAP.get(datum.idEvent) || { id: 0, name: "", icon: '', color: "" };
                contSelection.select(".icon-event").html(datum.iconSVGRaw);

                // elemnt.select("img").attr("src", evto.icon)
                const titleSubt = contSelection.select(".title-subtitle");
                titleSubt.select(".name").text(datum.title)
                titleSubt.select(".description").text("");
                titleSubt.select(".date").text(datum.dateFmt);

                if (datum.idEvent == EEventId.PHOTOS) {
                    titleSubt.select(".description")
                        .selectAll<HTMLImageElement, typeof datum.items[number]>(".item-foto")
                        .data(datum.items)
                        .join("img")
                        .attr("class", "item-foto")
                        .attr("src", d => _GetEventPhotoURL(d.idFoto, 3, d.date.toISOString()))
                        .on("click", (_, d) => new ImageViewer()
                            .SetImage(_GetEventPhotoURL(d.idFoto, 1, d.date.toISOString())))
                }
                else if (datum.description?.length)
                    titleSubt.select(".description").append(() => _AutoLink(datum.description)); // FIXME nop usar .html()
            })

        const bubblesReport = this.bodyContainer.selectAll<HTMLDivElement, IReportEvent>(".item-ev-report");
        bubblesReport.style("margin-bottom", null)
        this.bodyContainer.select(".item-ev-report:last-child").style("margin-bottom", "200px")

        if (this.bodyContainer.selectAll<HTMLDivElement, any>(":scope>.grupo").nodes().length > 1) {
            const currentFocusGroup = _APP_DATA.userData.IdGrupo;
            if (currentFocusGroup) {
                const grupoElement = this.bodyContainer.selectAll<HTMLDivElement, any>(`.grupo-${currentFocusGroup}`).nodes().pop();
                if (!grupoElement) return;
                grupoElement.scrollIntoView({ block: "start" })
            }
        }
    }
}