import { apiGrupoTeachersInfo, apiLoadGroups, apiLoadGroupsById } from "../API";
import { _APP_DATA } from "../AppData";
import { IApiResult, IGroup, IGrupoInfo, IInfoTeacher } from "../models/Entities";
import { RequestData } from "../utils/RequestData";

export function _LoadGroups(callback: (groups: IGroup[]) => void) {
    let params = {
        IdUsuario: _APP_DATA.userData.IdUsuario
    }

    new RequestData<IApiResult<IGroup[]>>(apiLoadGroups)
        .Params(params)
        .Completion((response, error) => {
            let resultData = response?.Datos || [];

            if (error) {
                console.log(error.name, error.message);
            }
            resultData.forEach(d => {
                _gruposInfoMap.set(d.IdGrupo, {
                    Id: d.IdGrupo,
                    Nombre: d.NombreGrupo,
                })
            })

            if (callback) callback(resultData);
        }).Get();
}

export const _gruposInfoMap = new Map<number, IGrupoInfo>();
export function _LoadGroupsByIds(idsGrupos: number[]) {
    const params = {
        Ids: idsGrupos,
    }
    return new Promise<typeof _gruposInfoMap>(resolve => {
        new RequestData<IApiResult<IGrupoInfo[]>>(apiLoadGroupsById)
            .Params(params)
            .Completion((response, error) => {
                const resultData: IGrupoInfo[] = response.Datos || [];
                if (error) {
                    console.log(error.name, error.message);
                }
                resultData.forEach(d => {
                    _gruposInfoMap.set(d.Id, d);
                })
                resolve(_gruposInfoMap);
            })
            .Get();
    })
}

export var _mapTeachersInfo: Map<number, IInfoTeacher> = new Map();
var maxDateTeacherInfo: string | null = null;
export function _LoadGroupTeachersInfo(callback?: (data: Array<IInfoTeacher>) => void) {
    const params = {
        IdGrupo: _APP_DATA.userData.IdGrupo,
        Modificacion: maxDateTeacherInfo
    }

    new RequestData<IApiResult<IInfoTeacher[]>>(apiGrupoTeachersInfo)
        .Params(params)
        .Completion((response, error) => {
            let resultData = response?.Datos || [];
            if (error)
                console.log(error.name, error.message);
            resultData.forEach(item => {
                _mapTeachersInfo.set(item.IdTeacher, item);
            })

            let maxDate = response?.Maxima || null;
            if (resultData.length > 0 && maxDate != null)
                maxDateTeacherInfo = maxDate;
            if (resultData.length > 0 && callback) callback(resultData);
        }).Get()
}

export function _ClearMaestrosInfoData() {
    _mapTeachersInfo.clear();
    maxDateTeacherInfo = null;
}