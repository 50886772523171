import "../../../styles/pages/Classroom.scss";
import { BaseUI } from "./BaseUI";
import { _LoadDataChild, _LoadTodaySchoolHour, _mapChilds } from "../../data/services/Kids";
import { Simulation, forceCollide, forceLink, forceManyBody, forceSimulation, select, drag, SimulationNodeDatum, Selection, DragBehavior, easeLinear } from "d3";
import { CreateListOptionEvents, ShowHideListOptionsEvents } from "../components/ListOptionEvents";
import { LOADING } from "../components/Loading";
import { EStatusSleepChild, IChild, IEvent, IEventStateHistory } from "../../data/models/Entities";
import { _Click } from "../utils/EventClick";
import { _PushState } from "../../routes/UIManager";
import { ShowToast } from "../components/Toast";
import { _ValidRegisterEventChild } from "../../data/utils/General";
import { _SnoozeAnimation, _childSleep } from "../components/SleepIndicator";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { _EnterGroup, _OutKinder, _RefuseEntry } from "../../data/services/Kinder";
import { _APP_DATA } from "../../data/AppData";
import _L, { _HttpMsg } from "../../utils/Labels";


export abstract class BClsRoom extends BaseUI {

    private listBubblesContainer: Selection<SVGSVGElement, any, any, any>;
    private listEventOptions: Selection<HTMLDivElement, any, any, any> | null = null;

    protected childList: IChild[] = [];

    private simulation: Simulation<any, any>;
    private WIDTH_PROFILE: number = 100;
    private BORDER_CIRCLE: number = 2.5;
    private SIZE_SVG = [100, 100];

    protected IS_EXTEMPORAL: boolean;

    constructor() {
        super({ addOptionsInHeader: true, addMainLogoOptions: true, className: 'class-room' });

        //Corregir y ajustarlo desde CSS, por ahora es SVG
        if (window.innerHeight < 500) {
            this.WIDTH_PROFILE = 70;
            this.BORDER_CIRCLE = 1.8;
        }
        // console.log(window.innerHeight)

        LOADING.Show();
        //Simulation
        this.simulation = forceSimulation()
            .force("link", forceLink().id(function (d: any) { return d.IdChild; }))
            .force("charge", forceManyBody().strength(0))
            // .force("x", forceX(function (d: any) { return d.x }))
            // .force("y", forceY(function (d: any) { return d.y }))
            .force("collide", forceCollide((this.WIDTH_PROFILE / 2) + 1).iterations(1))
            .on("tick", () => this.ticked());



        this.CreateOptionsEvents();
        this.CreateBubbleChilds();

        this.LoadChild();
    }

    protected abstract LoadChild(): void;

    private CreateBubbleChilds() {
        const marginTop = 6;
        const { height: contH } = this.bodyContainer.node().getBoundingClientRect();
        this.listBubblesContainer = this.bodyContainer.append("svg")
        this.listBubblesContainer
            .attr("width", this.listBubblesContainer.node().clientWidth) //contW)
            .attr("height", contH - (marginTop * 2))
            // .style("transform", "translate(0, 3%)")
            .style("margin-top", marginTop + "px")
            .classed("list-bubbles-child", true);

        let bounds = this.listBubblesContainer.node()?.getBoundingClientRect();
        this.SIZE_SVG = [bounds?.width || 100, bounds?.height || 100];

        const radius = this.WIDTH_PROFILE / 2;
        this.listBubblesContainer.append("clipPath")
            .attr("id", "cut-off-profile")
            .append("circle")
            .attr("cx", radius)
            .attr("cy", radius)
            .attr("r", radius - this.BORDER_CIRCLE)

        this.SetListBubbleChild();

        const svgParentLine = this.mainContainer.insert("svg", ".main-options")
            .classed("svg-line-container", true)
            .attr("width", "100%")
            .style("transform", "translate(0, 0)")
            .attr("height", "5px");

        this.lineLongClick = svgParentLine
            .append("line")
            .classed("line-selected-all", true)
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", 0)
            .attr("y2", 0);
        // this.DragAllItems();
    }

    protected SetListBubbleChild() {
        const bubblesChild = this.listBubblesContainer.selectAll<SVGGElement, IChild>(".bubbles-child").data(this.childList);

        const radius = this.WIDTH_PROFILE / 2;

        const dragg = this.DraggBubble();

        const self = this;
        bubblesChild.exit().remove();
        bubblesChild.enter().append("g")
            .classed("bubbles-child", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                let bubble = elemnt.append("g").classed("bubble-child", true);

                bubble.append("circle")
                    .attr("r", radius)
                    .attr("cx", radius)
                    .attr("cy", radius)
                    .attr("stroke-width", this.BORDER_CIRCLE * 2)

                bubble.append("foreignObject")
                    .attr("width", this.WIDTH_PROFILE - this.BORDER_CIRCLE)
                    .attr("height", this.WIDTH_PROFILE - this.BORDER_CIRCLE)
                    .html(`<div class="txt-name" xmlns="http://www.w3.org/1999/xhtml"><p></p></div>`);


                let img = bubble.append("image").classed("prof-child", true);
                img.attr("height", this.WIDTH_PROFILE)
                    .attr("width", this.WIDTH_PROFILE)
                    .attr("clip-path", "url(#cut-off-profile)")
                    .attr("preserveAspectRatio", "xMinYMin slice")
                    .attr("onerror", "this.style.display = 'none';");

            })
            .merge(bubblesChild)
            .call(_Click, function (evt, datum: IChild) {
                evt.stopPropagation();

                if (datum.GrupoActivo < 0) {
                    self.ShowDialogForEnter(datum);
                    return;
                }

                let elm = select(this);
                const imgElm = elm.select(".bubble-child");

                let isSelected = imgElm.classed("selected");
                imgElm.classed("selected", !isSelected);

                self.ShowHideEventOptions();
            })
            .call(dragg)
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);

                elemnt.select("circle").classed("female", datum.Sexo === 1)
                elemnt.select("circle").classed("outgroup", datum.GrupoActivo < 0)
                let bubble = elemnt.select<SVGGElement>(".bubble-child");
                bubble.select("image")
                    .style("display", "block")
                    .attr("href", datum.urlPhoto)
                    .attr("alt", datum.Nombre);

                bubble.select("foreignObject").select("div").select("p").text(datum.Nombre)


                // _SnoozeAnimation(bubble.select("foreignObject").select("div"), datum.EstadoEvt == EStatusSleepChild.DORMIDO)
                // AddCloudBubble(bubble, true)

                _childSleep(bubble.node(), radius, radius, false, datum.EstadoEvt != EStatusSleepChild.DORMIDO);
            });

        this.listBubblesContainer.attr("pointer-events", "all");

        const hasNewChilds = this.childList.some((d: any) => (d.x == null))
        this.SortBubblePosition(hasNewChilds);
    }

    private SortBubblePosition(reordenar = true) {
        //Ordenar lista por posicion
        if (!reordenar) {
            this.simulation.nodes(this.childList as any).restart();
            return;
        }

        let posInY = 2;
        let posInX = 2;

        this.childList.forEach((n: any) => {
            n.x = posInX;
            n.y = posInY;

            posInX += this.WIDTH_PROFILE + 20;
            if ((posInX + this.WIDTH_PROFILE) > this.SIZE_SVG[0]) {
                posInX = 0;
                posInY += this.WIDTH_PROFILE + 20;
            }
        });

        this.simulation.nodes(this.childList as any).restart();
    }

    private DraggBubble(): DragBehavior<SVGGElement, any, any> {
        const self = this;
        const [WIDTH, HEIGHT] = this.SIZE_SVG;
        let TEMP_POS: any[] = [];
        let dataSelectionBBle: any[] = [];

        const dragg = drag<SVGGElement, any>()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended)


        //DRAG EVTS:
        function dragstarted(ev: DragEvent | any, d: SimulationNodeDatum | any) {
            if (!ev.active) self.simulation.alphaTarget(0.3).restart()
            d.fx = d.x;
            d.fy = d.y;

            TEMP_POS = [];
            dataSelectionBBle = self.listBubblesContainer.selectAll(".bubbles-child").select(".bubble-child.selected")
                .data();
            dataSelectionBBle.forEach((item: any) => {
                TEMP_POS.push([item.x, item.y]);
            });

            //@ts-ignore
            self.SetCountSelection(this as any, dataSelectionBBle.length)
        }

        function dragged(ev: DragEvent, d: SimulationNodeDatum | any) {
            const _x = Math.min(Math.max(0, ev.x), WIDTH - self.WIDTH_PROFILE);
            const _y = Math.min(Math.max(0, ev.y), HEIGHT - self.WIDTH_PROFILE);
            d.fx = _x;
            d.fy = _y;

            // Pointer location
            let { clientX, clientY } = (ev["sourceEvent"] as TouchEvent).changedTouches
                ? (ev["sourceEvent"] as TouchEvent).changedTouches[0] || { clientX: 0, clientY: 0 }
                : (ev["sourceEvent"] as MouseEvent) || { clientX: 0, clientY: 0 };

            // clientX -= self.listBubblesContainer.node().getBoundingClientRect().left;
            clientY -= self.listBubblesContainer.node().getBoundingClientRect().top;
            clientX = Math.round(clientX);
            clientY = Math.round(clientY);
            self.EvaluarPosicionIcEvento(clientX, clientY);
            // self.EvaluarPosicionIcEvento(ev.x, ev.y)

            if (dataSelectionBBle.length < 2) return;

            dataSelectionBBle.forEach((item: any, i) => {
                const mrg = (i + 1) * 4;
                item.fx = _x - mrg;
                item.fy = _y - mrg;
            });
        }

        function dragended(ev: DragEvent | any, d: SimulationNodeDatum | any) {
            if (!ev.active) self.simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;

            self.CheckEventSelected();

            if (dataSelectionBBle.length < 2) return;

            //Regresar las burbujas
            dataSelectionBBle.forEach((item, i) => {
                item.fx = TEMP_POS[i][0];
                item.fy = TEMP_POS[i][1];
            });

            //@ts-ignore
            self.SetCountSelection(this, 0)
        }

        return dragg;
    }

    private CreateOptionsEvents() {
        this.listEventOptions = this.bodyContainer.append("div")
            .style("display", "none");

        CreateListOptionEvents(this.listEventOptions);
    }

    private ShowHideEventOptions() {
        const allSelectedBubbles = this.listBubblesContainer?.selectAll(".bubble-child.selected");
        if (allSelectedBubbles && allSelectedBubbles?.size() > 0) {
            const listChildSelected = allSelectedBubbles.data() as IChild[];
            const idx = listChildSelected.findIndex(o => o.GrupoActivo < 0)

            if (idx > -1) {
                ShowToast(_L("classroom.alumno_ent"), _L("classroom.alumnos_sin_g"), 'info');
                this.OnSelectDeselectItems(false);
            } else {
                // this.listEventOptions?.style("display", "flex")
                ShowHideListOptionsEvents(true);
            }
        } else {
            // this.listEventOptions?.style("display", "none")
            ShowHideListOptionsEvents(false);
            this.lineLongClick.attr("x2", 0);
        }
    }

    private ticked() {
        const node = this.listBubblesContainer?.selectAll<SVGGElement, any>(".bubbles-child");

        node?.attr("transform", (d) => {
            const _x = Math.min(Math.max(2, d.x), this.SIZE_SVG[0] - this.WIDTH_PROFILE - 2);
            const _y = Math.min(Math.max(2, d.y), this.SIZE_SVG[1] - this.WIDTH_PROFILE - 2);
            d.x = _x;
            d.y = _y;
            return "translate(" + _x + ", " + _y + ")";
        });
    }

    private SetCountSelection(gElement: any, n: number) {
        select(gElement).select(".g-count-selected").remove();

        if (n < 2) return;

        const szeIndc = 40;
        const radius = this.WIDTH_PROFILE / 2;
        const gCount = select(gElement).append("g")
            .classed("g-count-selected", true)
            .attr("transform", "translate(" + (radius * 2 - szeIndc) + ", 0)");
        gCount.append("rect")
            .attr("width", szeIndc)
            .attr("height", szeIndc)
            .attr("rx", szeIndc / 2)
            .attr("ry", szeIndc / 2)
            .attr("fill", "green");
        gCount.append("text").text("+" + n)
            .attr("x", szeIndc / 2)
            .attr("y", szeIndc / 2)
            .attr("text-anchor", "middle")
            .attr("alignment-baseline", "middle")
            .attr("dominant-baseline", "middle")
            .attr("fill", "#FFF")
    }


    /**
     * Por Corregir esta funcion!----
     * @param x Posicion del cursor en X
     * @param y Posicion del cursor en Y
     */
    private EvaluarPosicionIcEvento(x: number, y: number) {
        const elmEvts = this.listEventOptions?.selectAll(".opts-events");
        elmEvts?.classed("selected", false);
        let bounds = this.listEventOptions?.node()?.getBoundingClientRect() || { x: 0, y: 0, height: 0, width: 0 };

        // x = x + 120 + 60;//Evaluar medidas
        // y = y + 70;

        let sze = elmEvts?.size() || 0;
        const hItems = bounds.height / sze;
        // console.log(x + " -> " + bounds.x, y + " -> " + bounds.y, hItems)

        if (x > bounds.x && y > 0) {//Prueba.....
            const r = y / hItems;
            const p = Math.floor(r);

            let fnd = elmEvts?.filter(function (_, i) { return i === p; })
            fnd?.classed("selected", true);
        }
    }

    private CheckEventSelected() {
        const elmEvts = this.listEventOptions?.select(".opts-events.selected");
        if (elmEvts?.size() == 0) return;

        const allSelectedBubbles = this.listBubblesContainer?.selectAll<SVGGElement, IChild>(".bubble-child.selected");
        const listChildSelected = allSelectedBubbles.data();
        if (listChildSelected.length == 0) return;

        let datum: IEvent = elmEvts?.datum();

        const msgInfo = _ValidRegisterEventChild(listChildSelected, datum);
        if (msgInfo) {
            ShowToast(_L("classroom.reg_ev"), msgInfo, "info");
            elmEvts?.classed("selected", false);
            return;
        }

        const objState: IEventStateHistory = { isExtemp: this.IS_EXTEMPORAL, dataChilds: listChildSelected };
        _PushState(datum.hash, objState);
    }

    private longClickActive: boolean;
    private lineLongClick: d3.Selection<SVGLineElement, undefined, HTMLElement, any>;
    private maxWidth: number;

    protected DragAllItems() {
        let timeOut: NodeJS.Timeout | null = null;
        let bounds = this.mainContainer.node()?.getBoundingClientRect();
        this.maxWidth = bounds?.width || 100;

        const elmContainer = this.bodyContainer;
        let dblClickExpired: number = 0;

        const dragg = drag<any, any>()
            .on('start', (ev) => {
                const evSrc = ev.sourceEvent;
                if (evSrc.timeStamp <= dblClickExpired) {
                    evSrc.preventDefault();//Eliminar eventos posteriores
                    this.SortBubblePosition(); //Reorganizar
                    dblClickExpired = 0;
                    return;
                } else
                    dblClickExpired = evSrc.timeStamp + 400;// Tiempo para considerar dobleClick

                this.LongClick(false);
            })
            .on('end', () => {
                if (!this.longClickActive) {
                    if (timeOut !== null) {
                        clearTimeout(timeOut);
                        timeOut = null;
                    } else {
                        timeOut = setTimeout(() => {
                            timeOut = null;
                            this.OnSelectDeselectItems(false);
                        }, 200);
                    }

                    this.LongClick(true);
                }

                this.longClickActive = false;
            });

        elmContainer.call(dragg);
    }

    private LongClick(isClosing = false): void {
        this.lineLongClick
            .classed("active", false)
            .transition()
            .duration(isClosing ? 300 : 700)
            .ease(easeLinear)
            .attr("x2", !isClosing ? this.maxWidth : 0)
            .on("end", () => {
                this.longClickActive = !isClosing;
                if (!isClosing) {
                    this.lineLongClick.classed("active", true)
                    this.OnSelectDeselectItems(true);
                }
            });
    }

    private OnSelectDeselectItems(toSelect: boolean) {
        const allbbles = this.listBubblesContainer.selectAll(".bubbles-child").select(".bubble-child");
        allbbles.classed("selected", toSelect);
        this.ShowHideEventOptions();
    }

    private ShowDialogForEnter(child: IChild) {
        const options = [
            { id: 1, label: _L("classroom.entrada_si") },
            { id: 2, label: _L("classroom.entrada_no") },
        ];

        new ConfirmDialog()
            .SetTitle(_L("classroom.entraa_grupo", child.Nombre))
            .AddRadioOptions(options)
            .SetOnConfirm((val: typeof options[number]) => {

                new ConfirmDialog()
                    .SetTitle(_L("classroom.entraa_grupo", child.Nombre))
                    .SetDescription(_L(val.id == 1 ? "classroom.confirm_entrada_si" : "classroom.confirm_entrada_no", child.Nombre))
                    .SetOnConfirm(() => {
                        LOADING.Show();
                        if (val.id == 1)
                            this.CheckInGroup(child)
                        else
                            this.ValidateRefuseEntry(child)
                    });
            });
    }

    private CheckInGroup(child: IChild) {
        _EnterGroup(child.IdChild, (STATUS) => {
            const title = _L("classroom.entraa_grupo_title");
            if (STATUS === 1) {
                ShowToast(title, _L(child.EnKinder ? "classroom.alumno_llego_g" : "classroom.alumno_llego_esc", child.Nombre), "success");

                child.EnKinder = true;
                child.GrupoActivo = _APP_DATA.userData.IdGrupo;

                _mapChilds.set(child.IdChild, child);
                this.LoadChild();
                return;
            }
            else if (STATUS === -1) ShowToast(title, _HttpMsg("grupo/Entrada", STATUS), "info");
            else ShowToast(title, _HttpMsg("grupo/Entrada", STATUS) + ". " + _L("general.user_reintent"), "error");

            LOADING.Dismiss();
        });
    }

    private ValidateRefuseEntry(child: IChild) {
        _LoadTodaySchoolHour(child.IdChild, true, (status, result) => {
            if (status < 1) {
                ShowToast(_L("classroom.entraa_grupo_title"), _HttpMsg("alumno/ObtenerHorarioHoy", status), "error");
                LOADING.Dismiss()
                return;
            }

            if (result.length > 0) {
                this.ExecRefuseEntry(child);
            } else {
                this.ExecCheckOutKinder(child);
            }
        });
    }

    private ExecRefuseEntry(child: IChild) {
        _RefuseEntry(child.IdChild, (STATUS) => {
            const httpMsg = _HttpMsg("grupo/RechazarEntrada", STATUS, null, child.Nombre);
            if (STATUS > 0) {
                child.EnKinder = false;
                child.GrupoActivo = 0;

                _mapChilds.set(child.IdChild, child);
                ShowToast(_L("classroom.entraa_grupo_title"), httpMsg, "success");

                this.LoadChild();
            } else {
                ShowToast(_L("classroom.entraa_grupo_title"), httpMsg, "error");
                LOADING.Dismiss()
            }
        });
    }

    private ExecCheckOutKinder(child: IChild) {
        _OutKinder(child.IdChild, (STATUS) => {
            const httpMess = _HttpMsg("grupo/SalidaEscuela", STATUS, null, child.Nombre);
            if (STATUS > 0) {
                child.EnKinder = false;
                child.GrupoActivo = 0;

                _mapChilds.set(child.IdChild, child);
                ShowToast(_L("classroom.entraa_grupo_title"), httpMess, "success");

                this.LoadChild();
            } else {
                ShowToast(_L("classroom.entraa_grupo_title"), httpMess, "error");
                LOADING.Dismiss()
            }
        });
    }
}