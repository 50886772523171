import "../../../styles/pages/ChatList.scss";
import ic_back from '/icons/ic_back.svg?raw'
import ic_background_chat from '/icons/ic_logo_teacher_chat.svg?raw'

import { Selection, select } from "d3";
import { BaseUI } from "../bases/BaseUI";
import { IChild } from "../../data/models/Entities";
import { _mapChilds } from "../../data/services/Kids";
import { D3BubleChild, _AddBubbleChild } from "../utils/BubbleChild";
import { Chat } from "../views/Chat";
import { _APP_DATA } from "../../data/AppData";
import { _ID_CHAT_GROUP } from "../../data/utils/General";
import { _mapChildsMessageUnreaded } from "../../data/services/Chats";
import _L from "../../utils/Labels";
import { _CreateElementFromHTML } from "../utils/General";
import { _HistoryBack } from "../../routes/UIManager";
import { _LoadGroupTeachersInfo } from "../../data/services/Group";


export class ChatList extends BaseUI {
    private infoChatSelected?: Selection<HTMLDivElement, any, any, any>;
    private chatContainer: Selection<HTMLDivElement, any, any, any>;
    private listChatsContainer: Selection<HTMLDivElement, any, any, any>;
    private tempDivImage?: Selection<HTMLDivElement, any, any, any>;
    private messagesContainer: Selection<HTMLDivElement, any, any, any>;

    private childList: IChild[] = [];
    private childSelected: IChild;
    private clsChat: Chat | null = null;

    constructor() {
        super({ addMainLogoOptions: false, addOptionsInHeader: false, className: 'ui-chat-list' });

        _LoadGroupTeachersInfo();

        this.chatContainer = this.bodyContainer.append("div").classed("chat-container", true)
        this.listChatsContainer = this.chatContainer.append("div").classed("list-chat-child", true)

        this.RefreshChatsList();

        this.CreateHeaderChat();
        this.UpdateListChatsChild();

        this.messagesContainer = this.chatContainer.append("div").classed("chat-messages-container", true)
        this.CreateMessageContainer();


        //Monitorear mensajes no leidos
        this.timmerLoadUnreadedChat = 15000;
        this.LoadConfigAndunreadedMsg();
    }

    protected OnChangeOnreadedMessage(): void {
        this.UpdateListChatsChild();
    }

    private RefreshChatsList() {
        const objGroupCht: IChild = {
            IdChild: _ID_CHAT_GROUP,
            Nombre: _APP_DATA.userData.NombreGrupo,
            Sexo: _ID_CHAT_GROUP,//identificador grupo
            urlPhoto: "",
            unreadMessages: 0
        } as any;
        this.childList = [objGroupCht, ..._mapChilds.values()];
    }

    private CreateHeaderChat() {
        this.headerContainer.selectAll("*").remove();
        const headerChat = this.headerContainer.append("div").classed("header-chat", true);

        headerChat.append(() => _CreateElementFromHTML(ic_back))
            .attr("class", "back")
            .on("click", () => {
                _HistoryBack();
            });

        this.infoChatSelected = headerChat.append("div").classed("info-chat", true);
        this.infoChatSelected.append("h3");
        const bbleCont = this.infoChatSelected.append("div").classed("bble-container", true)
        _AddBubbleChild(bbleCont);
        this.infoChatSelected.style("visibility", "hidden")
    }

    private CreateMessageContainer() {
        //Agregar fondo de chat mientras no se ha seleccionado ningun niño
        this.tempDivImage = this.messagesContainer.append("div").classed("temp-chat-background", true);
        let tempValuesContainer = this.tempDivImage.append("div");
        // tempValuesContainer.append("img").attr("src", ic_background_chat)
        tempValuesContainer.html(ic_background_chat);
        tempValuesContainer.append("br")
        tempValuesContainer.append("h4").text(_L("chat.focuschat"));
    }

    private UpdateListChatsChild() {
        const bubblesChild = this.listChatsContainer.selectAll<HTMLDivElement, any>(".bubbles-child").data(this.childList);

        const self = this;
        bubblesChild.exit().remove();
        bubblesChild.enter().append("div")
            .classed("bubbles-child", true)
            .each((_, i, divs) => {
                const elemnt = select(divs[i]);
                _AddBubbleChild(elemnt);

                elemnt.select(".bubble-child").append("span")
                    .classed("num-messages", true)
            })
            .merge(bubblesChild)
            .on('click', function (_, datum) {
                self.chatContainer.select(".list-chat-child").select(".bubbles-child.selected").classed("selected", false);
                select(this).classed("selected", true);
                self.OnChatSelected(datum);
            })
            .each((datum, i, divs) => {
                const elemnt = select(divs[i]);

                const bubble = <D3BubleChild>elemnt.select("div");
                bubble.PhotoUrl(datum.urlPhoto)
                    .Name(datum.Nombre)
                    .Genre(datum.Sexo);

                bubble.classed("group", datum.Sexo == _ID_CHAT_GROUP)

                const nMsg = _mapChildsMessageUnreaded.get(_APP_DATA.userData.IdGrupo)?.get(datum.IdChild) || 0;
                bubble.select(".num-messages")
                    .classed("active", nMsg > 0)
                    .text(nMsg);
            });
    }

    private OnChatSelected(datum: IChild) {
        if (this.childSelected && this.childSelected.IdChild != datum.IdChild && this.clsChat) {
            this.clsChat.Destroy();
            this.clsChat = null;
        }

        this.childSelected = datum;

        //Mostrar info del niño/grupo seleccionado
        this.infoChatSelected?.style("visibility", "visible");
        this.infoChatSelected?.select("h3").text(datum.Nombre);

        const bubble = <D3BubleChild>this.infoChatSelected?.select(".bubble-child");
        bubble.PhotoUrl(datum.urlPhoto)
            .Name(datum.Nombre)
            .Genre(datum.Sexo);
        // this.infoChatSelected?.select("img").attr("src", datum.urlPhoto);

        if (!this.clsChat) {
            this.clsChat = new Chat(this.messagesContainer, this.childSelected);
            this.clsChat.OnUpdateUnreaded(() => {
                this.UpdateListChatsChild();
            });
        }
        // else
        //     this.clsChat._UpdateChatChild(this.childSelected)

        //Eliminar fondo de pantalla
        this.tempDivImage?.remove();
    }


    public OnDestroy(): void {
        super.OnDestroy();

        if (this.clsChat) {
            this.clsChat.Destroy();
            this.clsChat = null;
        }
    }
}