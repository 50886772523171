import { timeFormat } from "d3";

function GetValidDate(date: (string | Date)): (Date | null) {
    if (
        (typeof date == "string" && date.trim())
        || (date instanceof Date && !isNaN(Number(date)))
    ) {
        return new Date(date);
    }
    return null;
}

function GetDateTimeFormat(date: Date, formatOptions: Intl.DateTimeFormatOptions): string {
    // >> Language config
    let language: string = "es";
    if (navigator.language.toLowerCase().startsWith(language)) {
        language = navigator.language;
    }

    // >> Hour cycle
    let localesArgs = "-u";
    if (date.getHours() < 12) {
        localesArgs += "-hc-h11";
    } else {
        localesArgs += "-hc-h12";
    }

    // >>
    return new Intl.DateTimeFormat(language + localesArgs, formatOptions)
        .format(date);
    // return date?.toLocaleDateString(fn_GetLanguage(), formatOptions);
}

export type TDateStandarFormats = "d3_dd/mm/yyyy" | "dd/mm/yyyy" | "dd/mm/yy"
    | "dd/mm/yyyy h24:mm" | "dd/mm/yyyy h12:mm"
    | "h24:mm" | "h12:mm"
    | "MMM yyyy" | "d MMM yyyy"
    // | "dd d MMM yyyy"
    // | "dd d MMM yyyy h24:mm" | "dd d MMM yyyy h12:mm"
    | "dd/mm/yyyy h24:mm:ss" | "dd/mm/yyyy h12:mm:ss" | "h12:mm:ss" | "h24:mm:ss";

/**
     * @param date string | Date
     * @param format @default "dd/mm/yyyy"
     * @returns
     */
export function _DateFormatStandar(date: (string | Date), format: TDateStandarFormats = "dd/mm/yyyy"): (string | null) {
    let str = "";
    date = GetValidDate(date);

    if (date == null) {
        return null;
    }

    const dateOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }

    const hourOptions: Intl.DateTimeFormatOptions = {
        hour: "2-digit",
        minute: "2-digit",
        // second: "2-digit"
    }

    // // NOTE (...Intl.DateTimeFormat(undefined...): undefined para que tome el formato de lenguaje real del sistema

    switch (format) {
        case "d3_dd/mm/yyyy":
            str = timeFormat("%d/%m/%Y")(date);
            break;
        case "dd/mm/yyyy":
            str = GetDateTimeFormat(date, dateOptions);
            break;
        case "dd/mm/yy":
            dateOptions.year = "2-digit"
            str = GetDateTimeFormat(date, dateOptions);
            break;
        case "MMM yyyy":
            dateOptions.day = undefined;
            dateOptions.month = "long";
            str = GetDateTimeFormat(date, dateOptions);
            break;
        case "d MMM yyyy":
            dateOptions.day = "numeric";
            dateOptions.month = "long";
            str = GetDateTimeFormat(date, dateOptions);
            break;
        // case "dd d MMM yyyy":
        //     dateOptions.day = "numeric";
        //     dateOptions.month = "long";
        //     dateOptions.weekday = "long";
        //     str = fn_GetDateTimeFormat(date, dateOptions);
        //     break;
        // case "dd d MMM yyyy h24:mm":
        // case "dd d MMM yyyy h12:mm":
        //     dateOptions.weekday = "long";
        //     dateOptions.day = "numeric";
        //     dateOptions.month = "long";
        //     // str = fn_GetDateTimeFormat(date, dateOptions);
        //     str = fn_GetDateTimeFormat(date, {
        //         ...{
        //             hour12: (format == "dd d MMM yyyy h12:mm"),
        //         },
        //         ...dateOptions,
        //         ...hourOptions
        //     });
        //     break;
        case "dd/mm/yyyy h24:mm":
        case "dd/mm/yyyy h12:mm":
        case "dd/mm/yyyy h24:mm:ss":
        case "dd/mm/yyyy h12:mm:ss":
            if (format == "dd/mm/yyyy h12:mm:ss" || format == "dd/mm/yyyy h24:mm:ss") {
                hourOptions.second = "2-digit";
            }
            str = GetDateTimeFormat(date, {
                ...{
                    hour12: (format == "dd/mm/yyyy h12:mm" || format == "dd/mm/yyyy h12:mm:ss"),
                },
                ...dateOptions,
                ...hourOptions
            });
            break;
        case "h24:mm":
        case "h12:mm":
        case "h12:mm:ss":
        case "h24:mm:ss":
            if (format == "h12:mm:ss" || format == "h24:mm:ss") {
                hourOptions.second = "2-digit";
            }
            str = GetDateTimeFormat(date, {
                ...{
                    hour12: (format == "h12:mm" || format == "h12:mm:ss"),
                },
                ...hourOptions
            });
            break;
    }

    return str;
}