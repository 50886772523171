import '../styles/_main.scss';
import { select } from 'd3';
import { _GetAPPInfo } from './utils/Device.ts';
import { _Init } from './data/utils/General.ts';
import { LOADING } from './ui/components/Loading.ts';
import { _IS_SAFARI_NAVIGATOR } from "./utils/Device.ts";
import { BotSendError } from './utils/AlertBot.ts';
import { _ActiveObserveHistoryHash } from './routes/UIManager.ts';
import { ChangeSesionController } from './data/utils/ChangeSesionController.ts';


function InitAPP() {
    if (import.meta.env.PROD)
        window.addEventListener("contextmenu", function (e) { e.preventDefault(); })
    if (_IS_SAFARI_NAVIGATOR)
        document.body.classList.add('safari-browser');

    if (!IsOnLine())
        return;

    _ActiveObserveHistoryHash();

    _Init()
        .then(() => {
            LOADING.Show();
            _GetAPPInfo();

            // setTimeout(() => {
            //     //     let child = _mapChilds.get(84);
            //     //     _PushState("reportchild", child);
            //     _HashAssign("report");
            // }, 800);
        });
}

function IsOnLine(): boolean {
    let divLauncher = select(".launcher");
    if (!navigator.onLine) {
        divLauncher.append("h3")
            .text("No hay acceso a internet")
            .classed("title", true);
    } else {
        divLauncher.remove();
    }

    return navigator.onLine;
}

window.onload = () => {
    if (!window.innerHeight || !window.innerWidth) {
        console.info("fail window size > height: ", window.innerHeight, ", width: ", window.innerWidth);
        window.onresize = () => OnResize();
        return;
    }
    setTimeout(() => InitAPP(), 100);
}

window.onerror = (_message, _filename, _lineno, _colno, error) => {
    if (error) BotSendError(error);
}

window.onmessage = (e: MessageEvent) => setTimeout(() => ChangeSesionController._SaveAndEvalRequiredSesion(e.data)
    .then(res => {
        if (res) window.onmessage = null
    }))

function OnResize() {
    window.onresize = null;
    setTimeout(() => InitAPP(), 100);
}