import '../../../styles/pages/Login.scss'
import logoApp from '/icons/ic_logo_login.svg?raw'
import ic_eye from '/icons/ic_eye.svg?raw';
import ic_eye_slash from '/icons/ic_eye_slash.svg?raw';
import { CreateToast, ShowToast } from '../components/Toast';
import { Input } from '../components/Input';
import { LOADING } from '../components/Loading';
import { _InitSession, _RecoverPassword } from '../../data/services/UserInfo';
import { Base } from '../bases/Base';
import { _LoadUIMain, _RestartAndCreateDB } from '../../data/utils/General';
import { _SaveUserData } from '../../utils/Device';
import { _UpdateUserInfo } from '../../data/ManageUserInfo';
import { _Label as _L, _HttpMsg } from '../../utils/Labels';
import { _GetAppBuildTag } from '../../utils/General';
import { _CreateElementFromHTML } from '../utils/General';
import { ChangeSesionController } from '../../data/utils/ChangeSesionController';

export class Login extends Base {

    private divMain?: TSelection<"div">;

    private inputUser: Input;
    private inputPass: Input;

    constructor() {
        super('login', true);

        this.CreateLoginUI();
    }

    public CreateLoginUI() {
        // const mainContainer = select("body");
        this.mainContainer.append("div")
            .attr("class", "version_tag")
            .text(_GetAppBuildTag());

        this.divMain = this.mainContainer.append("div").classed("content-wrapper", true)
        // this.divMain.append("h1").text(_L("login_title"));

        this.divMain.append("div")
            .attr("class", "logo")
            .call((logo) => {
                logo.append(() => _CreateElementFromHTML(logoApp))
                    .style("height", "100%")
                    .style("width", "100%");
            })

        this.inputUser = new Input(this.divMain, { placeholder: _L("login.user"), className: 'user-input', inputType: 'email', required: true, autocomplete: "username", noValideTypeMismatch: true });
        this.inputUser.OnEnterKey(() => {
            this.inputPass?.FocusIn();
        });


        this.inputPass = new Input(this.divMain, { placeholder: _L("login.pass"), inputType: 'password', className: 'pass-input', required: true, autocomplete: "current-password", id: "current-password" })
        const imgEye = this.inputPass.lblInput.append("div").html(ic_eye).classed("ic-eye-pass", true);
        const imgEyeSlash = this.inputPass.lblInput.append("div").html(ic_eye_slash).classed("hide ic-eye-pass", true);

        this.inputPass.OnEnterKey(() => {
            this.OninitSession();
        });

        imgEye.node().onclick = e => {
            e.stopPropagation();
            imgEye.classed("hide", true);
            imgEyeSlash.classed("hide", false);
            this.inputPass?.input.attr("type", "text")
        };
        imgEyeSlash.node().onclick = e => {
            e.stopPropagation();
            imgEye.classed("hide", false);
            imgEyeSlash.classed("hide", true);
            this.inputPass?.input.attr("type", "password");
        };


        this.divMain.append("h4").text(_L("login.recoverpass")).classed("link-recove", true)
            .on("click", () => {
                this.RecoverPassword();
            });

        const btnLoggin = this.divMain.append("div").attr("class", "card-btn")
            .append("button").attr("type", "button").classed("btn-login", true)
            .text(_L("login.login"));


        btnLoggin.on("click", () => this.OninitSession());

        LOADING.Dismiss();

        ChangeSesionController._EvalAndStartRequiredSesion();
    }

    private OninitSession() {
        if (!this.inputUser.Validate() || !this.inputPass.Validate()) return;

        let user = this.inputUser.value;
        let pass = this.inputPass.value;

        LOADING.Show();

        _RestartAndCreateDB().then(success => {
            if (!success) {
                LOADING.Dismiss();
                return;
            }

            this.LoggIn(user, pass);
        });

    }

    private LoggIn(user: string, pass: string) {
        _InitSession(user, pass, (dataUser: IUsuario | null, resultStatus: number) => {
            console.log(">>>", dataUser)
            if (resultStatus === 1 && dataUser) {
                if (dataUser.IdNivel == 0) {
                    this.AssignUserData(dataUser, user, pass);
                    return;
                }

                this.EvaluateConfig(dataUser.IdNivel, (valid: boolean) => {
                    if (!valid) return;

                    this.AssignUserData(dataUser, user, pass);
                });
            } else {
                ShowToast(_L("login.login"), _HttpMsg("grupo/LoginV4", resultStatus), 'error');

                LOADING.Dismiss();
            }
        });
    }

    private AssignUserData(dataUser: IUsuario, user: string, pass: string) {
        let resData = dataUser as any;
        resData.username = user;
        resData.password = pass;

        _UpdateUserInfo(resData);

        _LoadUIMain();

        LOADING.Dismiss();
    }

    private RecoverPassword() {
        let user = this.inputUser?.value;
        if (!user) {
            ShowToast(_L("login.recoverpass"), _L("login.user_required"), "info");
            return;
        }

        LOADING.Show();
        _RecoverPassword(user, (response) => {
            LOADING.Dismiss();
            const success = response > 0;
            if (success) {
                this.inputUser.value = "";
                this.inputPass.value = "";
            }
            CreateToast({
                title: _L("login.recoverpass"),
                subtitle: _HttpMsg("sesion/RecuperarContrasenia", response),
                id: success ? "success" : "error",
            });
        });
    }

    public static _IniciarLoginExtern(_this: Login, usuario: string, contrasenia: string) {
        _this.inputUser.value = usuario;
        _this.inputPass.value = contrasenia;
        _this.OninitSession()
    }
}
