export const _URL_REGEX = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

// FIXME
export function _TimeToInputFmt(time = new Date()) {
    return time.getHours().toString().padStart(2, "0") + ":" + time.getMinutes().toString().padStart(2, "0");
}

// FIXME
export function _DateToInputFmt(time = new Date()) {
    return time.getFullYear() + "-" + (time.getMonth() + 1).toString().padStart(2, "0") + "-" + time.getDate().toString().padStart(2, "0");
}

export function _GetAppBuildTag() {
    return import.meta.env.APP_DEBUG_VERSION + "/" + process.env.APP_TAG
        + (import.meta.env.MODE == "production" ? "" : ` (${import.meta.env.MODE})`);
}
